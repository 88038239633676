<template>
  <div class="app-container">
    <div class="outer">
      <Steps :active-steps="activeSteps" />
      <div class="back-btn-parent">
        <el-button
          v-if="activeSteps === 1"
          class="back-btn"
          type="primary"
          size="small"
          @click="toBack()"
        >
          上一步
        </el-button>
      </div>

      <div v-if="activeSteps === 0">
        <SelectCat
          class="margin_right_10"
          @change="selectCat(arguments)"
        />
        <el-button
          class="margin_top_10"
          type="primary"
          size="mini"
          @click="toNext()"
        >
          下一步
        </el-button>
      </div>
      <div v-if="activeSteps === 1">
        <GoodsBase />
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss';
import Steps from './components/steps';
import SelectCat from './components/selectCat';
import GoodsBase from './components/goodsBase/index.vue';

export default {
  components: {
    Steps,
    SelectCat,
    GoodsBase,
  },
  data() {
    return {
      query: {},
      activeSteps: 0,
      goodsType: '',
      backendCategoryId: '',
      catId: [],
    };
  },
  mounted() {
    this.query = this.$route.query;
    this.activeSteps = this.query.activeSteps
      ? Number(this.query.activeSteps)
      : 0;
    this.goodsType = Number(this.query.goodsType);
  },
  methods: {
    selectCat(e) {
      console.log(e);
      this.catId = e[0];
      this.goodsType = e[1];
    },
    toNext() {
      if (this.catId.length > 0) {
        this.activeSteps = 1;
        this.backendCategoryId = this.catId[this.catId.length - 1];
        this.$router.replace({
          query: {
            ...this.query,
            activeSteps: 1,
            goodsType: this.goodsType,
            backendCategoryId: this.backendCategoryId,
            type: 'add',
          },
        });
      } else {
        this.$message({
          message: '请选择类目',
          type: 'warning',
        });
      }
    },
    toBack() {
      this.$confirm('确定返回上一步吗？返回后商品信息将不会保存', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.activeSteps = 0;
          this.goodsType = null;
          this.backendCategoryId = '';
          this.catId = [];
          this.$router.replace({
            path: '/nb/goods/add',
            query: {
              storeId: this.$route.query.storeId,
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
</style>
