<template>
  <el-cascader-panel
    v-model="catList"
    class="popper_class"
    :options="levelArr"
    :props="levelOptions"
    clearable
    size="mini"
    @change="handleLevelChange"
  />
</template>

<script>
export default {
  data() {
    const isNotSellGoodsType = [2, 3].includes(+this.$route.query.goodsBuyType) ? 1 : 0;
    return {
      catList: [],
      levelArr: [], // 类目下拉列表数据
      levelOptions: {
        checkStrictly: false,
        label: 'name',
        value: 'id',
        lazy: true,
        lazyLoad: this.lazyLevelLoad,
      },
      isNotSellGoodsType,
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    handleLevelChange(value) {
      console.log(value);
      let code;
      this.levelArr.forEach((item) => {
        if (item.id == value[0]) {
          code = item.code;
        }
      });
      this.$emit('change', value, code);
    },
    getCategoryList() {
      let that = this;
      that.$axios.get(
        that.$api.backendCategory.backend_category_list,
        {
          params: {
            currentPage: 1,
            pageSize: 100,
            parentId: 0,
            isBackendList: 0,
            isNotSellGoodsType: this.isNotSellGoodsType,
          },
        },
      ).then((res) => {
        if (res.code === 0) {
          const list = _.get(res.data, 'records', []);
          list.forEach((item) => {
            if (item.childrenNum == 0) {
              this.$set(item, 'leaf', true);
            }
          });
          that.levelArr = that.levelArr.concat(list);
        }
      });
    },
    lazyLevelLoad(node, resolve) {
      let that = this;
      const { level, value } = node;
      console.log(node, 'node');
      if (level > 0 && value) {
        that.$axios({
          method: 'get',
          url: that.$api.backendCategory.backend_category_list,
          params: {
            currentPage: 1,
            pageSize: 100,
            parentId: value,
            isBackendList: 0,
            isNotSellGoodsType: this.isNotSellGoodsType,
          },
        }).then((res) => {
          if (res.code === 0) {
            console.log(res.data.records, 999);
            if (res.data.records.length > 0) {
              res.data.records.forEach((item) => {
                if (item.childrenNum === 0) {
                  item.leaf = true;
                }
              });
              resolve(res.data.records);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popper_class {
  :deep(.el-cascader-menu__wrap) {
    height: 350px;
  }
}
</style>
