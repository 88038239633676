var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-cascader-panel", {
    staticClass: "popper_class",
    attrs: {
      options: _vm.levelArr,
      props: _vm.levelOptions,
      clearable: "",
      size: "mini",
    },
    on: { change: _vm.handleLevelChange },
    model: {
      value: _vm.catList,
      callback: function ($$v) {
        _vm.catList = $$v
      },
      expression: "catList",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }