var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c("Steps", { attrs: { "active-steps": _vm.activeSteps } }),
        _c(
          "div",
          { staticClass: "back-btn-parent" },
          [
            _vm.activeSteps === 1
              ? _c(
                  "el-button",
                  {
                    staticClass: "back-btn",
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toBack()
                      },
                    },
                  },
                  [_vm._v(" 上一步 ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.activeSteps === 0
          ? _c(
              "div",
              [
                _c("SelectCat", {
                  staticClass: "margin_right_10",
                  on: {
                    change: function ($event) {
                      return _vm.selectCat(arguments)
                    },
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "margin_top_10",
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.toNext()
                      },
                    },
                  },
                  [_vm._v(" 下一步 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.activeSteps === 1 ? _c("div", [_c("GoodsBase")], 1) : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }